import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import SbEditable from 'storyblok-react'
import renderBlok from '@renderBlok'
import { makeStyles } from '@material-ui/styles'
import { Box, Container } from '@material-ui/core'
import { H1, H4, Icon } from '@system'
import { isInBrowser } from '@helpers'
import get from 'lodash/get'
import { loadWistiaScript } from '@helpers/load-wistia-script'

const useStyles = makeStyles((theme) => {
  return {
    videoPlaylistRoot: {
      backgroundColor: (props) =>
        props.backgroundColor
          ? props.backgroundColor
          : theme.palette.background.primary,
      maxWidth: '100%',
    },
    container: {
      paddingBottom: '75px',
    },
    title: {
      fontSize: '48px',
      lineHeight: '60px',
      alignItems: 'center',
      textAlign: 'center',
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightLight,
      paddingTop: '50px',
      marginBottom: '50px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px',
        lineHeight: '36px',
      },
    },
    boldTitle: {
      fontSize: '40px',
      fontWeight: theme.typography.fontWeightExtraBold,
      width: '60%',
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px',
        lineHeight: '36px',
        width: 'auto'
      },
    },
    playerAndList: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    videoAndDesc: {
      display: 'flex',
      flexDirection: 'column',
      width: '60%',
      [theme.breakpoints.down('sm')]: {
        margin: '0 auto',
        width: '100%',
      },
    },
    playerContainer: {
      position: 'relative',
    },
    player: {
      width: '100%',
      height: '352px',
      margin: '0 auto',
      boxSizing: 'content-box',
      [theme.breakpoints.down('sm')]: {
        height: '199px',
        width: '354px',
        marginBottom: '30px',
      },
    },
    playButton: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: 'auto',
      border: 'none',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      width: 'fit-content',
      height: 'fit-content',
    },
    hiddenPlayButton: {
      display: 'none',
    },
    playlistContainer: {
      margin: '0 auto',
      display: 'flex',
      height: '352px',
      flexDirection: 'column',
      overflowY: 'scroll',
      overflowX: 'hidden',
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
        width: '354px',
      },
    },
    anchor: {
      textDecoration: 'none',
      display: 'flex',
    },
    notPlaying: {
      filter: 'opacity(75%)',
    },
    spacer: {
      width: '10px',
      height: '10px',
    },
    nowPlayingInfo: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    currentTitle: {
      fontSize: '18px',
      lineHeight: '24px',
      marginTop: '35px',
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightBold,
    },
    description: {
      fontSize: '18px',
      lineHeight: '28px',
      display: 'flex',
      alignItems: 'center',
      marginTop: '30px',
      color: theme.palette.text.tertiary,
      fontWeight: theme.typography.fontWeightRegular,
    },
  }
})

// This remove method is recommended for Wistia cleanup
const cleanup = () => {
  let wistiaObj = Wistia.api('nowPlaying')
  if (wistiaObj) {
    wistiaObj.remove()
    wistiaObj = null
  }
}

const addVideo = (videoId, setCurrentlyPlaying, setIsPlaying) => {
  if (isInBrowser) {
    window._wq = window._wq || []
    _wq.push({
      id: videoId,
      onReady: function (video) {
        video.bind('play', function () {
          setCurrentlyPlaying(video._hashedId)
          setIsPlaying(true)
          return video.unbind
        })
        document
          .getElementById(`custom-play-button-${video._hashedId}`)
          .addEventListener('click', () => {
            video.play()
          })
      },
    })
  }
}

const VideoPlaylist = (props) => {
  const classes = useStyles(props.blok)
  const { items, title, showDescription, variant, autoplayOff } = props.blok
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const [isPlaying, setIsPlaying] = useState(!autoplayOff)
  const [currentVideoId, setCurrentVideoId] = useState(
    get(items, '[0].videoId')
  )
  const [currentVideoDescription, setCurrentVideoDescription] = useState(
    get(items, '[0].description')
  )
  const [currentVideoTitle, setCurrentVideoTitle] = useState(
    get(items, '[0].description')
  )

  useEffect(() => {
    loadWistiaScript()
    setScriptLoaded(true)
  }, [scriptLoaded])

  useEffect(() => {
    items.forEach((video) =>
      addVideo(video.videoId, setCurrentVideoId, setIsPlaying)
    )
  }, [items])

  useEffect(() => {
    const d = items.find((x) => x.videoId === currentVideoId).description
    setCurrentVideoDescription(d)
  }, [items, currentVideoId])

  useEffect(() => {
    const t = items.find((x) => x.videoId === currentVideoId).title
    setCurrentVideoTitle(t)
  }, [items, currentVideoId])

  const formatPlaylistPosition = (index) =>
    `${(index + 1).toString().padStart(2, '0')}: `

  return (
    <SbEditable content={props.blok}>
      <Container className={classes.videoPlaylistRoot}>
        <Container className={classes.container}>
          {title && (
            <H1
              component="h2"
              className={classNames(classes.title, {
                [classes.boldTitle]: variant === 'master-class',
              })}
            >
              {title}
            </H1>
          )}
          <Box className={classes.playerAndList}>
            <Box className={classes.videoAndDesc}>
              <Box className={classes.playerContainer}>
                <Box
                  className={classNames(
                    'now_playing',
                    'wistia_embed',
                    `autoPlay=${!autoplayOff}`,
                    'playlistLinks=auto',
                    'playlistLoop=true',
                    `wistia_async_${currentVideoId}`,
                    classes.player
                  )}
                  data-analytics="wistia-playlist"
                  id="nowPlaying"
                />
                <button
                  id={`custom-play-button-${currentVideoId}`}
                  aria-label={`Play Video: ${currentVideoTitle}`}
                  className={classNames(classes.playButton, {
                    [classes.hiddenPlayButton]: isPlaying,
                  })}
                >
                  <Icon>VideoPlayCircle</Icon>
                </button>
              </Box>
              <Box className={classes.nowPlayingInfo}>
                {showDescription &&
                  currentVideoDescription &&
                  currentVideoTitle && (
                    <H4 className={classes.currentTitle}>
                      {currentVideoTitle}
                    </H4>
                  )}
                {showDescription && currentVideoDescription && (
                  <Box className={classes.description}>
                    {currentVideoDescription}
                  </Box>
                )}
              </Box>
            </Box>
            <Box className={classes.spacer} />
            <Box className={classes.playlistContainer}>
              {items.map((item, i) => (
                <a
                  href={`#wistia_${item.videoId}`}
                  key={item.videoId}
                  className={classNames(classes.anchor, {
                    [classes.notPlaying]: currentVideoId !== item.videoId,
                  })}
                  onClick={(e) => {
                    let wistiaObj = Wistia.api('nowPlaying')
                    setCurrentVideoId(item.videoId)
                    if (wistiaObj) {
                      wistiaObj.replaceWith(item.videoId)
                    }
                  }}
                >
                  {renderBlok(item, {
                    isNowPlaying: currentVideoId === item.videoId,
                    playlistPosition:
                      variant === 'master-class'
                        ? formatPlaylistPosition(i)
                        : '',
                    cleanup,
                  })}
                </a>
              ))}
            </Box>
          </Box>
        </Container>
      </Container>
    </SbEditable>
  )
}

export default VideoPlaylist
